import React, {useState} from "react";
import '../css/Toaster.css';

import { Error, Warning, ThumbUp, CheckCircle, AccessAlarm, NotificationsActive, Cancel } from "@mui/icons-material";

const Toaster = (props) => {

let toasterColor = props.toasterColor ? props.toasterColor : 'bg-red-600';
let toasterText = props.toasterText ? props.toasterText : 'Toaster Text Missing';
let toasterShow = props.toasterShow ? props.toasterShow : false;
let toasterOnClose = props.toasterOnClose ? props.toasterOnClose : null;
let toasterIcon = props.toasterIcon ? props.toasterIcon : null;
let toasterId = props.toasterId ? props.toasterId : "toasterWrapper";

//We need the toaster close phase to stop re-renders from refreshing the toaster while it is closing out
const [toasterClosePhase, setToasterClosePhase] = useState(false);

    //Toaster
    const closeToaster = () => {
        setToasterClosePhase(true);
        let toasterWrapper = document.querySelector("#"+toasterId);
        toasterWrapper.classList.remove('toaster-in');
        toasterWrapper.classList.add('toaster-out');
        console.log("out");
       

        setTimeout(() => {
            toasterOnClose();
            setToasterClosePhase(false);
          }, 2000);
       
    }

    if(toasterShow && !toasterClosePhase) {

       let toasterWrapper = document.querySelector("#"+toasterId);
        toasterWrapper.classList.remove('toaster-out');
        toasterWrapper.classList.add('toaster-in');
        console.log("in");
    }

    const getToasterIcon = () => {
   
        switch(toasterIcon) {
            case "error":
                    return (<Error />);
                break;
            case "warning":
                    return (<Warning />);
                break;
            case "checkCircle":
                    return (<CheckCircle />);
                break;
            case "thumbsUp":
                    return (<ThumbUp />);
                break;
            case "notification":
                    return (<NotificationsActive />);
                break;
            default:
                return;
        }
    }
    
    return(

        <div>
            <div id={toasterId} onClick={()=>{closeToaster()}} className={toasterColor + " toaster text-sm text-white rounded-md shadow-lg cursor-pointer"} role="alert">
                <div className="flex p-3">
                <p id="toasterComponent">{getToasterIcon()}
                
                    &nbsp; {toasterText}</p>

                <div className="ml-auto">
                    <button type="button" className="ml-4 inline-flex flex-shrink-0 justify-center items-center rounded-md text-white/[.5] hover:text-white focus:outline-none ">
                    <span className="sr-only">Close</span>
                    <Cancel />
                    </button>
                </div>
                </div>
            </div>
        </div>

    )
}

export default Toaster;


/*

setTimeout(()=>{
                toasterWrapper.style.opacity = 1
            }, 2000);
*/