import React, { useState, useEffect, useRef } from "react";
import {motion, AnimatePresence, useInView, useAnimation} from "framer-motion";
import Toaster from "../../Toaster";
import SideBarNav from "./SideBarNav";
import DropDown from "../../../controls/DropDown"
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import axios from 'axios';
import './home.css';

import { HashLink as Link } from 'react-router-hash-link';

const Home = (props) => {
    
    //Want the body to be black, but if you set this in CSS, all pages would inherit black background.
    const body = document.getElementsByTagName('Body')[0];
          body.style.backgroundColor = "#000000";

    const SIGNATURE = "Barrows Connected Store Home Page :: v.1.0.4 :: "
    const lg = (message) =>{
        let log = true;
        if(log) {
            return message;
        }
    };

    const [hasLoggedSignature, setHasLoggedSignature] = useState(false);
   

    if(!hasLoggedSignature) {
        console.log(lg({"sig":SIGNATURE}));
        setHasLoggedSignature(true);
    }

    //Toaster
    const [toasterShow, setToasterShow] = useState(false);
    const [toasterColor, setToasterColor] = useState("bg-green-500");
    const [toasterText, setToasterText] = useState("");
    const [toasterIcon, setToasterIcon] = useState("checkCircle");

    const toasterCloseHandler = () => {
        console.log(lg({"sig":SIGNATURE, "function":"toasterCloseHandler"}));
        setToasterShow(false)
        console.log(lg({"sig":SIGNATURE, "function":"toasterCloseHandler", "showToaster":toasterShow}));
    }

    const [apiToastNotification, setAPIToastNotification] = useState(false);
    const API_DEV = "http://127.0.0.1:5000/";
    const API_PROD = "https://api.barrowsconnectedstore.com/"
    let API_URL = API_PROD;

 
    if(window.location.href === 'http://localhost:3000/') {
        API_URL = API_DEV;
    }
    //HOOKS
    const SEND_MAIL = "send-mail";


    const headerVideoSRC = "https://assets.barrowsconnectedstore.com/home/videos/cs-multi-retailer-fly-through-mix.mp4";
    const imageDirectory = "https://assets.barrowsconnectedstore.com/home/images/";
    const WalmartCuePoint = 8;
    const TescoCuePoint = 20;
    const ShopRiteCuePoint = 36;

    const [backgroundHighlightColor, setBackgroundHighlightColor] = useState("barrows");
    const [textHighlightColor, setTextHighlightColor]  = useState("barrows-text");
    const [dotTextureHighlightColor, setDotTextureHighlightColor] = useState("barrows-dot");
    const [brandHighlight, setBrandHighlight] = useState("barrows-border-color");
    
    const [showSideBar, setShowSideBar] = useState(false);

    const brandLogoSection = useRef(null)
    const brandLogoSectionInView = useInView(brandLogoSection);
    const brandLogoAnimation = useAnimation();
    
    const ref2 = useRef(null);
    const sectionTwoInView = useInView(ref2);
    const sectionTwoAnimation = useAnimation();

    const retailMediaSection = useRef(null);
    const retailMediaSectionInView = useInView(retailMediaSection);
    const retailMediaSectionAnimation = useAnimation();

    const campaignMetricsRef = useRef(null);
    const campaignMetricsInView = useRef(campaignMetricsRef, true);
    const campaignMetricsSectionAnimation = useAnimation();

    const contactUsSection = useRef(null);
    const contactUsSectionInView = useInView(contactUsSection);
    const contactUsSectionAnimation = useAnimation()
    

    //Number counters
    let [campaignSectionAnimating, setCampaignSectionAnimating] = useState(false);
    let [campaigns, setCampaigns] = useState(0);
    let [networkDisplays, setNetworkDisplays] = useState(10);
    let [networkStores, setNetworkStores] = useState(1);
    const [totalCampaigns, setTotalCampaigns] = React.useState(60);

    

    let totalDisplays = 10;
    let totalStores = 2;

    //API hooks
    const API = (hook, payload) => {
        //let toasterWrapper = document.querySelector("#toasterWrapper");
        //let toaster = document.querySelector("#toasterComponent");
        switch(hook) {
            case SEND_MAIL:
                    axios.post(API_URL + SEND_MAIL, payload)
                    .then(res => {
                        const api_response_object = res.data;
                        console.log(lg({"sig":SIGNATURE, "function":"API-SEND_MAIL", "endpoint": API_URL, "response_object":JSON.stringify(api_response_object)}));
                        
                        switch(Number(api_response_object.status_code)) {
                            case 202:
                                    //setToasterColor("bg-green-600");
                                    //toaster.innerHTML = "Your inquiry was successfully sent."
                                    setToasterText("Your email was successfully sent.");
                                    setToasterColor("bg-green-600");
                                    setToasterIcon("thumbUp");
                                    setToasterShow(true);
                                break;
                            case 500:
                                    //setToasterColor("bg-red-600");
                                    //toaster.innerHTML = "Your inquiry failed.  Please try again."
                                    setToasterText("Your inquiry failed.  Please try again.");
                                    setToasterColor("bg-red-600");
                                    setToasterIcon("error");
                                    setToasterShow(true);
                                break;
                            default:
                                console.error(lg({"sig":SIGNATURE, "function":"API-SEND_MAIL", "message":"Status code missing from API response"}));
                        
                        }
                        
                        //Animate the toaster in
                        //toasterWrapper.classList.remove("toaster-out");
                        //toasterWrapper.classList.add("toaster-in");
                        //setTimeout(()=>{toasterWrapper.style.opacity = 1}, 2000);

                    })
                break;
            default:
                console.error("No hook passed in API call");
        }   
    }


    //Form initial items
    const [regionInitialItem, setRegionInitialItem] = useState("Choose one");
    const [industryInitialItem, setIndustryInitialItem] = useState("Choose one");

    //Form data
    const [formData, setFormData] = useState({
        firstName:{
            value:"",
            required:true
        },
        lastName: {
            value:"",
            required:true
        },
        email:{
            value:"",
            required:true
        },
        region:{
            value:"",
            required:false
        },
        industry:{
            value:"",
            required:false
        },
        message:{
            value:"",
            required:false
        }
    })
   
    //Form validation
    const validateEmail = (email) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(email)
    };

    //Manages setting industry for the form
    const SetContactFormIndustry = (props) => {
        //console.log(lg({"sig":SIGNATURE, "function":"SetContactFormIndustry", "props":props}));
        let tmpData = formData;
        tmpData.industry.value = props.title;
        setFormData(tmpData);
    }

    const SetContactFormRegion = (props) => {
        //console.log(lg({"sig":SIGNATURE, "function":"SetContactFormRegion", "props":props}));
        let tmpData = formData;
        tmpData.region.value = props.title;
        setFormData(tmpData);

    }

    const validateForm = (event) => {

        //console.log(lg({"sig":SIGNATURE, "function":"validateForm", "payload":event}));

        let valid = false;
        let elm = document.querySelector("#"+event.target.id);
        let elmRequired = event.target.required;
        let messageId = event.target.id + "Message";
        let elmMessage = document.querySelector("#"+messageId);
        
        try {
                //Make sure required values are not empty
                if(event.target.value === '' && elmRequired) {
                            
                    elmMessage.innerHTML = "* This is a required field";
                    elm.classList.add('form-error-highlight');
                    elm.focus();

                } else {

                    //Need to further validate email
                    if(event.target.id === 'email') {
                    
                        if(validateEmail(event.target.value)) {
                            try {
                                elmMessage.innerHTML = "";
                                elm.classList.remove('form-error-highlight');
                            } catch(e) {
                                //console.log(e)
                            }
                            formData.email.value = event.target.value;
                            valid = true;

                        } else {

                            elmMessage.innerHTML = "* Make sure you add a valid email";
                            elm.classList.add('form-error-highlight');
                            elm.focus();

                            
                        }

                    } else {
                        //If not empty make sure the highlight is cleared
                        elmMessage.innerHTML = "";
                        elm.classList.remove('form-error-highlight');
                        valid = true;
                    }
                
                }
                    let enableSubmitButton = true;
                    for (const [ky, val] of Object.entries(formData)) {
                        //console.log(lg({"sig":SIGNATURE, "function":"validateForm", "objectEntries":`${ky}: ${val.value} - ${val.required}`}));
                        //We only store the value if the value is valid.  So, we just need to check if all required fields are populate
                        if(val.value === '' && val.required === true) {
                            //console.log(ky + " was required and not populated")
                            enableSubmitButton = false;
                        }
                    }
                    if(enableSubmitButton) {
                        //console.log("ALL CLEAR!!!!")
                        document.querySelector("#submitButton").enabled = true;
                    }

        } catch(e) {
            console.error(lg({"sig":SIGNATURE, "function":"validateForm", "error":e}));
            
        }
       
        return {"valid": valid, "id":event.target.id, "value": event.target.value};

    }
   
    const updateForm = (event) => {
        
        //console.log(lg({"sig":SIGNATURE, "function":"updateForm", "payload":event}));
        
        let validateObj = validateForm(event);
        if(validateObj.valid) {
            let tmpData = formData;
            tmpData[event.target.id]["value"] = event.target.value;
            setFormData(tmpData);
            
            //console.log(lg({"sig":SIGNATURE, "function":"formData", "payload": JSON.stringify(formData)}));
        }
        
        //See if all the required fields are populated
       
    }

    const submitForm = (event) => {
        
        event.preventDefault();
        

        //let emailTemplate = `<p><b>Sender:</b> ${formData.firstName.value} ${formData.lastName.value} </p><p><b>Email:</b> <a mailto:="${formData.email.value}"/>${formData.email.value}</p><p><b>Region:</b> ${formData.region.value} </p><p><b>Industry:</b> ${formData.industry.value} </p> <p><b>Message: </b> ${formData.message.value}`

        let payload = {};
            //payload.message = emailTemplate;
            //payload.to = ["kurt.hold@barrowsglobal.com", "tim.bagwell@barrowsglobal.com"];
            //payload.subject = "Connected Store Contact Form Submission";

            payload.template = "home";
            payload.firstName = formData.firstName.value;
            payload.lastName = formData.lastName.value;
            payload.email = formData.email.value;
            payload.region = formData.region.value;
            payload.industry = formData.industry.value;
            payload.message = formData.message.value;

            API(SEND_MAIL, payload);
            document.querySelector("#contactForm").reset()
            setRegionInitialItem("Choose one");
            setIndustryInitialItem("Choose one");

    }

    /* Side Bar Navigation */
    const toggleSideBar = () => {
        console.log(lg({"sig":SIGNATURE, "function":"toggleSideBar"}));
        if(showSideBar === true) {
            setShowSideBar(false);
        } else {
            setShowSideBar(true);
        }
    }

    /* End Side Bar Navigation */
    const [counter, setCounter] = useState(0);
    const [devNoticeShown, setDevNoticeShownState] = useState(false);
    
    useEffect(() => {
    

        const video = document.querySelector("#csHomeVideo");
        
        const videoIsPlaying = (video) => {
            return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        };

        //Manages highlight elements
        const highlightInt = () => {
            //console.log("videoIsPlaying", videoIsPlaying(video));
            if(!!videoIsPlaying) {
                //console.log("videoIsntPlaying")
                //video.currentTime = counter;
                //console.log("video.currentTime", video.currentTime, counter);
                //video.play();
            }
            if(counter < 55) {
                setCounter(state => state + 1);
                if(counter < WalmartCuePoint) {
                    //console.log("Barrows");
                 }
         
                 if(counter >= WalmartCuePoint && counter < TescoCuePoint) {
                     setBackgroundHighlightColor("walmart");
                     setTextHighlightColor("walmart-text");
                     setDotTextureHighlightColor("walmart-dot");
                     setBrandHighlight("walmart-border-color");
                 }
         
                 if(counter >= TescoCuePoint && counter < ShopRiteCuePoint) {
                     setBackgroundHighlightColor("tesco");
                     setTextHighlightColor("tesco-text");
                     setDotTextureHighlightColor("tesco-dot");
                     setBrandHighlight("tesco-border-color");
                 }
         
                 if(counter >= ShopRiteCuePoint) {
                     setBackgroundHighlightColor("shoprite");
                     setTextHighlightColor("shoprite-text");
                     setDotTextureHighlightColor("shoprite-dot");
                     setBrandHighlight("shoprite-border-color")
                 }
            } else {
                setCounter(state => 0);
            }
            
        }
       
        /* END HIGHLIGHT CONTENT SECTION */

        /* brandLogoSectionInView */
        if(brandLogoSectionInView) {
         
            //console.log(lg({"sig":SIGNATURE, "message":"brandLogoSectionInView"}))
            brandLogoAnimation.start("visible");
        }

        /* ANIMATE WHEN IN VIEW SECTION */
   

        if(campaignMetricsInView) {
       
          
            
            if(campaignSectionAnimating === false) {
               
                setCampaignSectionAnimating(true);
                

            }
            
        } else {
            
            setCampaignSectionAnimating(false);
           
        }

        if(sectionTwoInView) {
            sectionTwoAnimation.start("visible");
        }
        /* END ANIMATE WHEN IN VIEW SECTION */


        if(retailMediaSectionInView) {
            retailMediaSectionAnimation.start("visible");
        }

        if(contactUsSectionInView) {
            contactUsSectionAnimation.start("visible");
        }
        /* CALL FUNCTIONS THAT NEED TO UPDATE ON INT */
        const countInt = setInterval(highlightInt, 1000);

        /* CLEAR COUNT INTERVAL */
        return () => {
          clearInterval(countInt)
        }

      }, [
        counter, 
        brandLogoSectionInView, 
        campaignMetricsInView,
        sectionTwoInView,
        retailMediaSectionInView,
        contactUsSectionInView
    ]);

    // State Gallery
    const [selectedImage, setSelectedImage] = useState(false);
    

    useEffect(() => {
        if(API_URL === API_DEV) {
            setToasterText("Caution! You are using the DEV API");
            setToasterIcon("warning")
            setToasterColor("bg-yellow-500");
            setToasterShow(true);
        }
    }, [])

    return(
        <div className="page-wrapper">
            <SideBarNav 
                showSideBar={showSideBar}
                backgroundColor={backgroundHighlightColor}
                closeSideBar={toggleSideBar} />
            <Toaster 
                toasterShow={toasterShow} 
                toasterColor={toasterColor}
                toasterText={toasterText}
                toasterOnClose={toasterCloseHandler}  
                toasterIcon={toasterIcon}
            ></Toaster>
           
            {/* HEADER SECTION */}
            <div className="header-grid bg-black">
                <AnimatePresence>
                    <motion.div
                        key="headerNavContainer"
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1}}
                        exit={{opacity:0}}
                        transition={{type:"easeIn", delay:2, duration:1}}
                        className={"header-nav text-white"}
                    >
                            <div className="menu-icon-container"><IconButton onClick={()=> {toggleSideBar()}} variant="text" style={{color: grey[50]}}><MenuIcon className="menu-icon" /></IconButton></div>
                            <div><img className="logo" src={imageDirectory + "logo.png"} alt="Connected Store logo" /></div>
                            <div className="top-nav"><Link  to="#contact-us">CONTACT US</Link>&nbsp; | &nbsp; <a href="https://portal.barrows.tech/auth/login" target="_blank" rel="noreferrer">LOGIN</a></div>
                    </motion.div>
                </AnimatePresence>
               
              
                <div className="video-container">
                    <video id="csHomeVideo"  className="video" width="100%"  autoPlay muted webkit-playsinline="true">
                        <source src={headerVideoSRC} type="video/mp4"/>
                    </video>
                </div>
          
                
                <AnimatePresence>
                    <motion.div
                        key="headlineContainer"
                        initial={{ y: -100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1}}
                        exit={{opacity:0}}
                        transition={{type:"spring", bounce: 0.35, duration:1, delay:3}}
                        className={"headline-content"}
                    >
                        <div id="headlineContent" className="headline-content text-white">
                                Digital Media <br/> And Merchandising <br/> <span className={textHighlightColor}>Platform</span>
                        </div>
                    </motion.div>
                </AnimatePresence>

               
                
                <AnimatePresence>
                    <motion.div  
                        key="calloutBoxOne"
                        initial={{ x: -300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1}}
                        exit={{opacity:0}}
                        transition={{type:"easeIn", duration:1, delay:6}}
                        className={backgroundHighlightColor + " callout-box callout-box-1"}>
                        <div className="callout-box-1-card">
                        <div className={backgroundHighlightColor +  " callout-box-1-pill"}>&nbsp;</div>
                        <div className="text-white callout-box-1-content">
                            Build A <br></br><span className="x-bold-txt">RM 2.0</span> <br></br>Offering
                        </div>
                        </div>        
                    </motion.div>
                </AnimatePresence>

                <AnimatePresence>
                    <motion.div  
                        key="calloutBoxTwo"
                        initial={{ y: +300, opacity: 0 }}
                        animate={{ y: 0, opacity: 1}}
                        exit={{opacity:0}}
                        transition={{type:"easeIn", duration:1, delay:6}} 
                        className={backgroundHighlightColor + " callout-box callout-box-2"}>
                        <div className="callout-box-2-card">
                           <div className="text-white callout-box-2-content">
                                Enhance <br></br> Shopping <br></br><span className="x-bold-txt">Experiences</span>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>

                <AnimatePresence>
                    <motion.div  
                        key="calloutBoxThree"
                        initial={{ x: +300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1}}
                        exit={{opacity:0}}
                        transition={{type:"easeIn", duration:1,  delay:6}}
                        className={backgroundHighlightColor + " callout-box callout-box-3"}>
                        <div className="callout-box-3-card">
                            <div className="text-white callout-box-3-content">
                                Reduce <br></br> Your <br></br><span className="x-bold-txt">Carbon <br></br> Footprint</span>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
            {/* END HEADER SECTION */}

             {/* RETAILER LOGO AND METRICS SECTION */ }
             <div id="network" className={dotTextureHighlightColor + " brand-section"}>
                    <div ref={brandLogoSection} className="section-headline">
                            <div className="text-white">Select <b>Network</b> Partners And Stats</div>  
                            <motion.div 
                                variants={{
                                    hidden: { opacity:0, x:100},
                                    visible: { opacity:1, x:0}
                                }}
                                initial="hidden"
                                animate={brandLogoAnimation}
                                transition={{ duation: 2, delay:0.50}}
                                className={backgroundHighlightColor + " headline-pill"}>
                        </motion.div>
                        <motion.div 
                        
                            variants={{
                                hidden: { opacity:0, y:-100},
                                visible: { opacity:1, y:0}
                            }}
                            initial="hidden"
                            animate={brandLogoAnimation}
                            transition={{ duation: 10, delay:.5}}
                            className="flex align-center justify-center logos-section text-white">
                                <img src="https://assets.barrowsconnectedstore.com/home/images/logos.png" alt="Retailer logos" />
                        </motion.div>
                        <div ref={campaignMetricsRef} className="partner-metrics flex text-white">
                                <div className="w-[33%]">
                                    <div className="metric-top-number text-white">
                                        {totalCampaigns}K

                                    </div>
                                    <div className="metric-bottom-text">LIVE BRAND MESSAGES</div>
                                </div>
                                <div className="w-[33%]">
                                    <div className="metric-top-number">{networkDisplays}K</div>
                                    <div className="metric-bottom-text">NEWTWORK DISPLAYS</div>
                                </div>
                                <div className="w-[33%]">
                                    <div className="metric-top-number">{networkStores}K</div>
                                    <div className="metric-bottom-text">NETWORK STORES</div>
                                </div>
                        </div>
                    </div>
             </div>

                {/* THIS IS THE GALLERY SECTION */ }
                <div id="gallery" ref={ref2} className={"gallery-section " }>
                    <div className="section-headline">
                            <div className="text-white">Example <b>Work</b></div>  
                            <motion.div 
                                variants={{
                                    hidden: { opacity:0, x:1000},
                                    visible: { opacity:1, x:0}
                                }}
                                initial="hidden"
                                animate={sectionTwoAnimation}
                                transition={{ duation: 1, delay:0.50}}
                                className={backgroundHighlightColor + " headline-pill"}>
                       
                        </motion.div>
                    </div>
                    {/* <div className="gallery-images ">
                           
                                <div className="col-1">
                                    <div><img src={imageDirectory + "/gallery-1.png"} className="gallery-image" alt="Item one"/></div>
                                    <div className="flex grow mt-5">
                                        <div className="flex-row row-2-col-1">
                                            <div><img src={imageDirectory + "/gallery-3.png"} className="gallery-image" alt="Item three"/></div>
                                            <div className="mt-5"><img src={imageDirectory + "/gallery-5.png"} className="gallery-image" alt="Item five"/></div>
                                        </div>
                                        <div className="flex-row row-2-col-2">
                                            <div><img src={imageDirectory + "/gallery-4.png"} className="gallery-image" alt="Item four"/></div>
                                            <div className="mt-5"><img src={imageDirectory + "/gallery-6.png"} className="gallery-image" alt="Item six"/></div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-2">
                                    <div><img src={imageDirectory + "/gallery-2.png"} className="gallery-image" alt="Item one"/></div>
                                    <div><img src={imageDirectory + "/gallery-7.png"} className="gallery-image mt-5" alt="Item seven"/></div>
                                </div>
                            
                    </div> */}

                    {/* RESPONSIVE GALLERY UPDATES */}
                    <div className="gap-4 grid grid-cols-2 md:gap-6 md:grid-cols-3 md:p-10 p-6">
                        {[
                            { alt: "Item one", cover: "h-full object-cover md:oject-fit md:h-auto", colSpan: 'col-span-2', src: "/gallery-1.png" },
                            { alt: "Item two", cover: "h-full object-cover", rowSpan: "row-span-2", src: "/gallery-2.png" },
                            { alt: "Item three", cover: "h-full object-cover md:oject-fit md:h-auto", src: "/gallery-3.png" },
                            { alt: "Item four", cover: "h-full object-cover", src: "/gallery-4.png" },
                            { alt: "Item five", src: "/gallery-5.png" },
                            { alt: "Item six", cover: "h-full object-cover", rowSpan: "row-span-2", src: "/gallery-6.png" },
                            { alt: "Item seven", src: "/gallery-7.png" },
                        ].map((item, index) => (
                            <div key={index} className={`${selectedImage === index? `col-span-2 md:col-span-3 ${item.colSpan || ''}`  : item.colSpan || ''} ${item.rowSpan || ''}`}>
                                <img className={`cursor-pointer ${item.cover || 'w-full'} w-full`} src={imageDirectory + item.src} alt={item.alt} onClick={() => selectedImage === index ? setSelectedImage(false) : setSelectedImage(index)} />
                            </div>
                        ))}
                    </div>
                    {/* END RESPONSIVE GALLERY UPDATES */}

                </div>
             {/* END GALLERY AND RETAIL SECTION  */ }
             
             <div id="retail-media" className={dotTextureHighlightColor + " retail-media"}>
                    {/* RETAIL MEDIA 2.0 SECTION */ }
                    <div className="rmn20-section">
                        <div ref={retailMediaSection} className="section-headline">
                                <div className="text-white">Build A <b>RMN 2.0</b> Offering</div>  
                                <motion.div 
                                    variants={{
                                        hidden: { opacity:0, x:100},
                                        visible: { opacity:1, x:0}
                                    }}
                                    initial="hidden"
                                    animate={retailMediaSectionAnimation}
                                    transition={{ duation: 2, delay:0.50}}
                                    className={backgroundHighlightColor + " headline-pill"}>
                            </motion.div>
                            
                            <div className="rmn-quote-block">
                                <p className="rmn-quote">"2024 is the year in which Retail Media 2.0-the next critical phase in retail media's evolution-hits its stride. And it's and era where retail media move up the funnel and into the store."</p>
                                <p className="rmn-quote-author-name">Andrew Lipsman</p>
                                <p className="rmn-quote-author-title">Media, Ads + Commerce</p>
                            </div>
                            <div className="rmn-overview">
                                Connected Store is a suite of tools that help retailers stand-up a best of class in-store digital media and merchandising platform.
                            </div>
                            {/* <div className="rm-content ">
                            
                                    <div className="rmn-col-1-content">
                                    <p className="sub-section-headline">01. Connect <span className={textHighlightColor + " text-900"}>OS</span></p>
                                    <p className="sub-section-article">Connect OS is Connected Stored proprietary platform operating system. Connect was designed with retail as its only objective. The tool is a collection of apps that solve key points of friction unique to retail and retail media.

    Media owners can easily package Connection Points into media segments for easy audience targeting and efficient flighting. Media Managers can utilize the campaign tools to efficiently build out programmatic and sponsored campaigns.

    In store, merchants and associates can leverage the platform to approve content launches, dynamically update content, quickly get unit support and manage inventory issues ensuring an optimal shopping experience. Analysts can measure campaign performance through our Insights dashboards and pull all data into their own internal BI tools through our analytics web hooks.</p>
                                    </div>
                                    <div className="rmn-col-2-image ">
                                    <div className="relative sub-section-image-container-right">
                                            <div className={backgroundHighlightColor + " numbered-image-bubble-right"}>01</div>
                                            <img src={imageDirectory + "/connect-os.png"} alt="Connect OS" />
                                    </div>
                                    </div>
                                
                            </div>
                            <div className="rm-content ">
                            <div className="rmn-col-1-image ">
                                <div className="relative sub-section-image-container-left">
                                    <div className={backgroundHighlightColor + " numbered-image-bubble-left"}>02</div>
                                    <img src={imageDirectory + "/connection-point.png"} alt="Connection Point" />
                                </div>
                            </div>
                            <div className="rmn-col-2-content">
                                <p className="sub-section-headline">02. Connection <span className={textHighlightColor + " text-900"}>Points</span></p>
                                <p className="sub-section-article">Connection Points are custom engineered beautifully crafted digital, physical and sensorial retail touchpoints. We work hard to design them to weave seamlessly into the retail experience. The result is units that feel native to shopping even when their objective is not specifically connected to product or category merchandising.

    The cladding, the lighting, the media all work together to help make shoppers more brand aware and fully informed.</p>
                            </div>
                            
                        
                            </div>
                            <div className="rm-content ">
                            
                            <div className="rmn-col-1-content">
                                <p className="sub-section-headline">03. Connect <span className={textHighlightColor + " text-900"}>Insights</span></p>
                                <p className="sub-section-article">Connect includes a suite of insights to prove out performance.  Operations can leverage the platform to pull proof-of-play logs to demonstrate compliance.  Analysts can pull detailed engagement reporting to understand dwell, interaction, and conversion, and support can query tickets to rapidly address Connection Point issues. All insights are available for self-service in the platform or via API to your BI tool of choice.</p>
                            </div>
                            <div className="rmn-col-2-image ">
                                <div className="relative sub-section-image-container-right">
                                    <div className={backgroundHighlightColor + " numbered-image-bubble-right"}>03</div>
                                    <img src={imageDirectory + "/connect-insights.png"} alt="Connect Insights" />
                                </div>
                            </div>
                        
                    </div> */}

                      {/* RESPONSIVE CONNECT SECTION UPDATES */}
                      {[
                            {
                                number: '01',
                                title: 'OS',
                                content: `Connect OS is Connected Stored proprietary platform operating system. Connect was designed with retail as its only objective. The tool is a collection of apps that solve key points of friction unique to retail and retail media. Media owners can easily package Connection Points into media segments for easy audience targeting and efficient flighting. Media Managers can utilize the campaign tools to efficiently build out programmatic and sponsored campaigns. In store, merchants and associates can leverage the platform to approve content launches, dynamically update content, quickly get unit support and manage inventory issues ensuring an optimal shopping experience. Analysts can measure campaign performance through our Insights dashboards and pull all data into their own internal BI tools through our analytics web hooks.`,
                                imgSrc: "/connect-os.png",
                                imgAlt: "Connect OS",
                                textFirstMd: true,
                            },
                            {
                                number: '02',
                                title: 'Points',
                                content: `Connection Points are custom engineered beautifully crafted digital, physical and sensorial retail touchpoints. We work hard to design them to weave seamlessly into the retail experience. The result is units that feel native to shopping even when their objective is not specifically connected to product or category merchandising. The cladding, the lighting, the media all work together to help make shoppers more brand aware and fully informed.`,
                                imgSrc: "/connection-point.png",
                                imgAlt: "Connection Point",
                                textFirstMd: false,
                            },
                            {
                                number: '03',
                                title: 'Insights',
                                content: `Connect includes a suite of insights to prove out performance. Operations can leverage the platform to pull proof-of-play logs to demonstrate compliance. Analysts can pull detailed engagement reporting to understand dwell, interaction, and conversion, and support can query tickets to rapidly address Connection Point issues. All insights are available for self-service in the platform or via API to your BI tool of choice.`,
                                imgSrc: "/connect-insights.png",
                                imgAlt: "Connect Insights",
                                textFirstMd: true,
                            },
                        ].map((section, index) => (
                            <div key={index} className={`flex flex-col mt-[50px] ${section.textFirstMd ? 'md:flex-row' : 'md:flex-row-reverse'} text-white`}>
                                <div className={`${section.textFirstMd ? 'md:w-3/5' : 'md:w-1/2'} md:text-[0.8em] pr-[30px] w-full `}>
                                    <p className="sub-section-headline">{section.number}. Connect <span className={textHighlightColor + " text-900"}>{section.title}</span></p>
                                    <p className="sub-section-article">{section.content}</p>
                                </div>
                                <div className='md:w-1/2 md:pt-0 pt-6 w-full'>
                                    <div className={`relative sub-section-image-container-${section.textFirstMd ? 'right' : 'left'}`}>
                                        <div className={`${backgroundHighlightColor} numbered-image-bubble-${section.textFirstMd ? 'right' : 'left'}`}>{section.number}</div>
                                        <img src={`${imageDirectory}${section.imgSrc}`} alt={section.imgAlt} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* END RESPONSIVE CONNECT SECTION UPDATES */}

                        </div>
                    </div>
             </div>
             {/* END RETAIL MEDIA 2.0 SECTION */}
             {/* CONTACT US SECTION */}
             <div id="contact-us">
             <div ref={contactUsSection} className="section-headline ">
                            <div className="text-white">Contact <b>US</b></div>  
                            <motion.div 
                                variants={{
                                    hidden: { opacity:0, x:100},
                                    visible: { opacity:1, x:0}
                                }}
                                initial="hidden"
                                animate={contactUsSectionAnimation}
                                transition={{ duation: 2, delay:0.50}}
                                className={backgroundHighlightColor + " headline-pill"}>
                        </motion.div>
            </div>
             <div className="gray-to-black-full-span contact-us">
                <div className="contact-form text-white">
                <form id="contactForm"  onSubmit={submitForm}>
                <div className="md:flex items-center mt-12">
                    <div className="w-full md:w-1/2 flex flex-col">
                        <label className="font-semibold leading-none ">* First Name <span className="form-required-message">(Required)</span><span id="firstNameMessage" className="form-error-message"></span></label>
                        <input type="text" id="firstName" required onBlur={updateForm} onChange={updateForm} className={brandHighlight + " leading-none text-gray-50 p-3 border-2 mt-4 form-input-background rounded focus:ring-0 focus:outline-0 focus:border-0"} />
                    </div>
                    <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                        <label className="font-semibold leading-none ">* Last Name <span className="form-required-message">(Required)</span><span id="lastNameMessage" className="form-error-message"></span></label>
                        <input type="text" required id="lastName" onBlur={updateForm} onChange={updateForm} className={brandHighlight + " leading-none text-gray-50 p-3 border-2  mt-4 form-input-background rounded focus:ring-0 focus:outline-0 focus:border-0"}/>
                    </div>
                </div>
                <div className="md:flex items-center mt-8">
                    <div className="w-full flex flex-col">
                        <label className="font-semibold leading-none ">* Email <span className="form-required-message">(Required)</span><span id="emailMessage" className="form-error-message"></span></label>
                        <input type="email" required id="email" onBlur={updateForm} onChange={updateForm} className={brandHighlight + " leading-none text-gray-50 p-3 border-3 mt-4 form-input-background rounded focus:ring-0 focus:outline-0 focus:border-0"}/>
                    </div>
                    
                </div>
                <div className="md:flex items-center mt-8">
                    <div className="w-full flex flex-col">
                        <label className="font-semibold leading-none ">Region</label>
                        <DropDown 
                            required
                            formControlContainerClasses={brandHighlight + " border-2 mt-4 w-[240px]  form-input-background rounded-md h-[43px] p-1"} 
                            formControlClasses="rounded-md relative justify-left w-[230px] text-gray-50 focus:ring-0 focus:outline-0 focus:border-0"
                            formControlChevron="h-5 absolute right-2 text-white text-sm pointer "
                            formControlMenu="form-input-background rounded-md text-white pointer text-sm"
                            formControlMenuText="text-white "
                            formControlMenuTextOver="bg-gray-200 text-black w-[97%] ml-1 rounded"
                            initialItem={regionInitialItem}
                            items={[
                                {"id":1, "title":"Canada"},
                                {"id":2, "title":"Europe"},
                                {"id":3, "title":"South Africa"},
                                {"id":4, "title":"United Kingdon"},
                                {"id":5, "title":"United States"},
                                {"id":6, "title":"Other"}
                            ]} callback={SetContactFormRegion} />
                    </div>
                    <div className="w-full flex flex-col">
                        <label className="font-semibold leading-none ">Industry</label>
                        <DropDown 
                            required
                            formControlContainerClasses={brandHighlight + " border-2 mt-4 w-[240px]  form-input-background rounded-md h-[43px] p-1"} 
                            formControlClasses="rounded-md relative justify-left w-[230px] text-gray-50 focus:ring-0 focus:outline-0 focus:border-0"
                            formControlChevron="h-5 absolute right-2 text-white text-sm pointer "
                            formControlMenu="form-input-background rounded-md text-white pointer text-sm"
                            formControlMenuText="text-white "
                            formControlMenuTextOver="bg-gray-200 text-black w-[97%] ml-1 rounded"
                            initalItem={industryInitialItem}
                            items={[
                                {"id":1, "title":"Agency"},
                                {"id":2, "title":"Brand"},
                                {"id":3, "title":"Retailer"},
                                {"id":4, "title":"Tech Provider"}
                            ]} callback={SetContactFormIndustry} />
                    </div>
                </div>
               
                <div>
                    <div className="w-full flex flex-col mt-8">
                        <label className="font-semibold leading-none ">* Message <span className="form-required-message">(Required)</span><span id="messageMessage" className="form-error-message"></span></label>
                        <textarea type="text" required id="message" onBlur={updateForm} onChange={updateForm} className={brandHighlight + " h-40 text-base leading-none text-gray-50 p-3  mt-4 form-input-background border-3 rounded focus:ring-0 focus:outline-0 focus:border-0"}></textarea>
                    </div>
                </div>
                <div className="flex w-full">
                    <button id="submitButton" className={backgroundHighlightColor + " mt-9 font-semibold leading-none text-white py-4 px-10  rounded"}>
                        Contact US
                    </button>
                </div>
            </form>
                </div>
            </div>
            </div>
             {/* END CONTACT US SECTION */}
      </div>
    )
}
export default Home



/*
  <div className="video-container">
                    <video id="csHomeVideo"  className="video" width="100%"  autoPlay muted webkit-playsinline="true">
                        <source src={headerVideoSRC} type="video/mp4"/>
                    </video>
                </div>

*/